.home-page-container {
  background: none;
  padding-top: 80px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .home-page-container {
    max-width: fit-content;
    padding-top: 60px;
  }
}

@media screen and (max-width: 1366px) {
  .home-page-container {
    max-width: 1293px;
  }
}

.slider img {
  width: 100%;
  object-fit: cover;
  height: 750px;
}

@media screen and (max-width: 768px) {
  .slider img {
    width: 100%;
    height: 620px;
  }
}

.slide-content {
  position: relative;
  display: flex;
  align-items: center;
}

.text-content {
  position: absolute;
  left: 18%;
  top: 40%;
  color: white;
  z-index: 2;
}

@media (max-width: 768px) {
  .text-content {
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}

.text-content h1 {
  font-size: 3.5rem;
  margin-bottom: 10px;
  line-height: 1.4;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .text-content h1 {
    font-size: 2.5rem;
    padding-top: 64px;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
  }
}

.text-content p {
  font-size: 1.5rem;
}
@media screen and (max-width: 768px) {
  .text-content p {
    font-size: 1.2rem;
    padding-left: 24px;
    padding-right: 24px;
    text-align: center;
    font-weight: bold;
  }
}

.slick-dots {
  bottom: 10px !important;
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots li button:before {
  font-size: 0;
}

.slick-dots li .custom-dot {
  background: rgba(255, 255, 255, 0.5);
}

.slick-dots li.slick-active .custom-dot {
  background: white;
}

.learn-more-button {
  background-color: transparent;
  color: white;
  border: 1px solid #fff;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
  border-radius: 50px;
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 48px;
}

.learn-more-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.responsive-image.image1 {
  content: url("./images/1.jpg");
}

.responsive-image.image2 {
  content: url("./images/2.jpg");
}

.responsive-image.image3 {
  content: url("./images/3.jpg");
}

@media (max-width: 768px) {
  .responsive-image.image1 {
    content: url("./images/1Small.jpg");
  }

  .responsive-image.image2 {
    content: url("./images/2Small.jpg");
  }

  .responsive-image.image3 {
    content: url("./images/3Small.jpg");
  }
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  z-index: 2;
  cursor: pointer;
}

.custom-arrow-next {
  right: 10px !important;
}

.custom-arrow-prev {
  left: 10px !important;
}

.custom-arrow:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.custom-arrow-next:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.custom-arrow-prev:before {
  transform: translate(-50%, -50%) rotate(-135deg);
}
